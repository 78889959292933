import React, {useState, useEffect} from 'react'
import {useStaticQuery, graphql, Link} from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import {Inner} from '../styles'
import {formatMoney} from '../../utils'

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
`
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 1050px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 750px) {
    grid-template-columns: 1fr;
  }
`
const ProductContainer = styled(Link)`
  transition: 0.2s all ease-in-out;
  box-shadow: 0 5px 15px 0 rgba(50, 50, 93, 0.07);
  margin: 20px;
  background: #fff;
  padding: 20px 20px 10px 20px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .button-pill {
    border-radius: 200px;
    border: 2px solid ${({theme}) => theme.grey2};
    color: ${({theme}) => theme.grey2};
    padding: 5px 20px;
    display: inline-block;
    width: fit-content;
    margin: 10px 0;
    transition: 0.2s all ease-in-out;
  }
  &:hover {
    transform: translateY(-5px);
    .button-pill {
      background: ${({theme}) => theme.redHover};
      border: 2px solid ${({theme}) => theme.redHover};
      color: #fff;
    }
  }
  @media (min-width: 750px) {
    .gatsby-image-wrapper {
      border: 1px solid #f2f2f2;
      & > div {
        padding-bottom: 100% !important;
      }
      img {
        object-fit: contain !important;
      }
    }
  }
`
const Title = styled.h3`
  padding: 10px 0;
  text-transform: initial;
  margin: 0;
`
const SubTitle = styled.div`
  border: none;
  margin: 0;
`
const Badge = styled.div`
  font-weight: 400;
  padding: 2px 8px;
  background: ${({theme}) => theme.grey5};
  color: rgba(0, 0, 0, 0.4);
  display: inline-block;
  margin: 5px 5px 5px 0;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 12px;
`
const Divider = styled.div`
  border-top: 5px solid
    ${({theme, main}) => (main ? theme.redHover : theme.grey2)};
  width: ${({main}) => (main ? '50px' : '30px')};
  margin: ${({main}) => (main ? '20px auto' : '15px 0')};
`
const Price = styled.h4`
  border: none;
  margin: 0;
  font-weight: bold;
  span {
    font-size: 10px;
    display: block;
    line-height: 10px;
    color: rgba(0, 0, 0, 0.4);
  }
`

export default function Products({filter, order}) {
  const {allWcProducts: edges} = useStaticQuery(graphql`
    query {
      allWcProducts(
        filter: {
          price: {ne: "0"}
          status: {eq: "publish"}
          catalog_visibility: {eq: "visible"}
        }
      ) {
        edges {
          node {
            wordpress_id
            name
            price
            sku
            slug
            categories {
              name
            }
            sale_price
            stock_status
            description
            attributes {
              name
              options
            }
            product_variations {
              price
              id
              sku
              stock_status
              attributes {
                name
                option
              }
            }
            images {
              src
              localFile {
                childImageSharp {
                  fluid(quality: 80, srcSetBreakpoints: [100]) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
                name
              }
            }
          }
        }
      }
    }
  `)

  const [products, setProducts] = useState(edges.edges)
  const [visibleProducts, setVisibleProducts] = useState()

  // Filter products according to whether it is part of the category we have selected
  const filterArrayByPropArgs = array => {
    return array.filter(({node}) =>
      node.categories.some(categ => categ.name === filter.name),
    )
  }

  const sortArrayByPropArgs = array =>
    order === 'ASC'
      ? array
          .slice()
          .sort((first, second) => first.node.price - second.node.price)
      : array
          .slice()
          .sort((first, second) => second.node.price - first.node.price)

  const filterAndSortArrayByPropParams = array =>
    sortArrayByPropArgs(filterArrayByPropArgs(array))

  useEffect(() => {
    setVisibleProducts(filterAndSortArrayByPropParams(products))
  }, [filter, order])

  return (
    <Container>
      <GridContainer>
        {visibleProducts &&
          visibleProducts.map(({node}) => (
            <ProductContainer to={`/product/${node.slug}/`} key={node.slug}>
              <SubTitle>
                <Badge
                  style={{
                    float: 'right',
                    fontWeight: 'bold',
                    backgroundColor:
                      node.stock_status == 'instock' ? `#21ba45` : `#d60734`,
                    color: 'hsla(0,0%,100%,.9)',
                  }}
                >
                  {node.stock_status == 'instock' ? 'IN STOCK' : 'OUT OF STOCK'}
                </Badge>
              </SubTitle>
              {node.images && node.images.length > 0 && (
                <Img
                  fluid={node.images[0]?.localFile?.childImageSharp?.fluid}
                  alt={node.name}
                />
              )}
              <Title>{node.name}</Title>
              <SubTitle>
                {node.categories.map(categ => (
                  <Badge key={categ.name}>{categ.name}</Badge>
                ))}
              </SubTitle>
              <Divider />
              <Price>
                <span>FROM</span>
                {formatMoney(node.price)}
              </Price>
              <div className="button-pill">Purchase</div>
            </ProductContainer>
          ))}
      </GridContainer>
    </Container>
  )
}
